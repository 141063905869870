export const routes = {
  home: "/",
  wallet: "/soluciones-de-pago/PagoFlash-Wallet",
  //contacto: "/contacto",
  business: "/soluciones-de-pago/PagoFlash-Business",
  login: "/login",
  soporte: "/soporte",
  commerce: "/soluciones-de-pago/PagoFlash-Commerce",
  nomina: "/soluciones-de-pago/PagoFlash-Nomina",
  products: "/soluciones-de-pago",
  //nosotros: "/nosotros",
  asesoria: "/agenda-una-asesoria",
  toc: "/terminos-y-condiciones",
  privacy: "/privacidad",
};
