import {
  Box,
  Button,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FC } from "react";
import DeviceFrame from "../devices/DeviceFrame";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

import bgParallax from "../../assets/images/parallax-teal.png";

interface Props {
  image: string;
  title: string;
  description: JSX.Element;
  variant: "color" | "white";
  items: {
    icon: string;
    text: JSX.Element;
  }[];
  onKnowMoreClick: () => any;
  onWatchTutorialClick: () => any;
}

const ProductSection: FC<Props> = ({
  image,
  title,
  description,
  items,
  variant,
  onKnowMoreClick,
  onWatchTutorialClick,
}) => {
  const isColorVariant = variant === "color";

  const bg = isColorVariant ? `url(${bgParallax})` : undefined;

  return (
    <Section bg={bg} spacing="sm">
      <Stack
        direction={{ base: "column", lg: "row" }}
        maxW="1600px"
        mx="auto"
        justifyContent="center"
        spacing={{ base: "4", lg: "32" }}
      >
        {/* <Box textAlign="center">
          <DeviceFrame
            device="iPhone X"
            size={useBreakpointValue({ base: 1.5, lg: 1 })}
          >
            <Box
              backgroundImage={`url('${image}')`}
              backgroundSize="cover"
              backgroundPosition="center"
              height="100%"
              width="100%"
            ></Box>
          </DeviceFrame>
        </Box> */}

        <Box flex="1">
          <SectionHeader color={"orange.500"} showDivider textAlign="center">
            {title}
          </SectionHeader>

          <Box fontSize="2xl" textAlign="center">
            {description}
          </Box>

          {items.length > 0 && (
            <SimpleGrid columns={{ base: 1, md: 3 }} my="16" gridRowGap="8">
              {items.map((item) => (
                <Box textAlign="center" key={item.icon}>
                  <Image src={item.icon} mx="auto" mb="4"></Image>
                  <Box fontSize="xl">{item.text}</Box>
                </Box>
              ))}
            </SimpleGrid>
          )}

          <HStack spacing="8" justifyContent="center">
            <Button
              bg="orange.500"
              color="white"
              onClick={onKnowMoreClick}
              rounded="base"
              px="8"
              py="6"
            >
              Conoce mas
            </Button>
            <Button
              bg={isColorVariant ? "white" : "#555"}
              color={isColorVariant ? "#555" : "white"}
              onClick={onWatchTutorialClick}
              rounded="base"
              px="8"
              py="6"
            >
              Ver tutorial
            </Button>
          </HStack>
        </Box>
      </Stack>
    </Section>
  );
};

export default ProductSection;
