import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Text,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import CustomParallax from "../components/custom/CustomParallax";
import Section from "../components/section/Section";
import SectionHeader from "../components/section/SectionHeader";
import bgHero from "../assets/images/hero-home.jpg";
import Footer from "../components/layout/Footer";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";

const FormPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    business: "",
    email: "",
    phone: "",
    nameBusiness: "",
    contact: "",
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const message = `¡Saludos! Quisiera agendar una asesoría, a continuación les dejo mis datos personajes:\n\nNombre: ${formData.name}\nTeléfono: ${formData.phone}\nCorreo: ${formData.email}\nModalidad de Negocio: ${formData.business}\nNombre de mi Negocio: ${formData.nameBusiness}\nQue Medio de Comunicación Prefiero: ${formData.contact}`;

    console.log("mjs", message);

    const whatsappURL = `https://wa.me/584242265478?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <Box backgroundImage={`url(${bgHero})`} width="100%" bgSize="cover">
      <Box
        my={{ base: "24", lg: "48" }}
        maxW="2xl"
        mx={"auto"}
        overflow="auto"
        position="relative"
        borderRadius={"5px"}
      >
        <CustomParallax backgroundSize="1000px">
          <Section spacing="sm">
            <SectionHeader
              color="white"
              textAlign="center"
              showDivider
              fontSize="4xl"
            >
              ¿Deseas agendar una reunión? <br></br>
            </SectionHeader>
            <Text
              fontSize="xl"
              textAlign="center"
              color="white"
              fontWeight="medium"
            >
              Nuestros asesores de venta pueden responder todas tus dudas a
              través de cualquiera de nuestros canales de atención: llamada,
              WhatsApp, Zoom o correo electrónico. Nos adaptamos a tus
              necesidades.
            </Text>
          </Section>
        </CustomParallax>
        <Box bgColor={"white"} px="8" py={"8"}>
          <Text fontSize="3xl" textAlign="center">
            ¡Es absurdamente sencillo!
          </Text>
          <form onSubmit={handleSubmit}>
            <Input
              placeholder="Nombre*"
              my="4"
              name="name"
              value={formData.name}
              required
              onChange={handleChange}
            ></Input>
            <Select
              placeholder="Modalidad de negocio*"
              name="business"
              color="black"
              my="4"
              value={formData.business}
              onChange={handleChange}
              required
            >
              <option value="Persona Natural" color="black">
                Persona Natural
              </option>
              <option value="Persona Jurídica">Persona Jurídica</option>
            </Select>
            <Input
              name="nameBusiness"
              placeholder="¿Cómo se llama tu negocio?"
              my="4"
              value={formData.nameBusiness}
              onChange={handleChange}
              required
            ></Input>
            <Select
              placeholder="¿Qué medio de contacto prefieres?*"
              name="contact"
              color="gray.400"
              textColor="black"
              my="4"
              value={formData.contact}
              onChange={handleChange}
              required
            >
              <option value="Llamada Telefonica">Llamada Telefonica</option>
              <option value="Chat de WhatsApp">Chat de WhatsApp</option>
              <option value="Meet por Zoom">Meet por Zoom</option>
            </Select>
            <Input
              name="email"
              placeholder="Correo electrónico*"
              my="4"
              value={formData.email}
              onChange={handleChange}
              required
            ></Input>
            <Input
              name="phone"
              placeholder="Teléfono*"
              my="4"
              value={formData.phone}
              onChange={handleChange}
              required
            ></Input>

            <Button
              mt={"4"}
              px="12"
              bg={"orange.500"}
              color={"white"}
              type="submit"
              _hover={{
                bg: "orange.600",
              }}
            >
              <Text fontSize={20}>
                <FaWhatsapp />
              </Text>
              <Text m={2}>¡ENVIAR!</Text>
            </Button>
          </form>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default FormPage;
