import { Box, BoxProps, ColorProps, Heading } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

interface Props extends BoxProps {
  color: ColorProps["color"];
  showDivider?: boolean;
}

const SectionHeader: FC<PropsWithChildren<Props>> = ({
  children,
  color,
  showDivider,
  ...props
}) => {
  return (
    <Box w="auto" px="" {...props}>
      <Box display="inline-block" textAlign="center">
        <Heading
          textAlign="inherit"
          color={color}
          fontWeight="medium"
          size={"2xl"}
          fontSize={props.fontSize}
        >
          {children}
        </Heading>

        {showDivider && (
          <Box
            bgColor={color}
            h="3px"
            w={"100px"}
            my="8"
            display="inline-block"
          ></Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionHeader;
