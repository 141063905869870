import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import Hero from "../components/layout/Hero";
import { CheckCircleIcon } from "@chakra-ui/icons";

import SectionHeader from "../components/section/SectionHeader";
import Section from "../components/section/Section";
import DeviceFrame from "../components/devices/DeviceFrame";

import bgHero from "../assets/images/hero-pagomail.jpg";
import bgParallaxTeal from "../assets/images/parallax-teal.png";
import pagoMail from "../assets/images/imgETFC1.png";

import Footer from "../components/layout/Footer";

import pagoMailBenefit1 from "../assets/images/pagomail-benefit-1.png";
import pagoMailBenefit2 from "../assets/images/pagomail-benefit-2.png";
import pagoMailBenefit3 from "../assets/images/pagomail-benefit-3.png";
import pagoMailBenefit4 from "../assets/images/pagomail-benefit-4.png";
import PreFooterSection from "../components/section/PreFooterSection";
import ConditionsSection from "../components/section/ConditionsSection";
import qrPagoFlashAPP from "../assets/images/qrPagoFlashAPP.png";
import masterTarjeta from "../assets/images/masterTarjeta.png";
import tarjetaPagoflash from "../assets/images/tarjeta-pagoflash.png";
import { routes } from "../routes/links";
import { useNavigate } from "react-router-dom";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import ConditionsSection2 from "../components/section/ConditionsSection2";
import ConditionsSection3 from "../components/section/ConditionsSection3";

interface PagoMailBenefit {
  icon: string;
  text: JSX.Element;
}

interface Recaudos {
  text: string;
}

const pagoMailBenefit: PagoMailBenefit[] = [
  {
    icon: pagoMailBenefit1,
    text: (
      <Text>
        Haz que tus empleados se sientan únicos y{" "}
        <Text as="span" color="orange.500">
          ofréceles un trato personalizado
        </Text>{" "}
        con PagoFlash Nómina (PSP)
      </Text>
    ),
  },
  {
    icon: pagoMailBenefit2,
    text: (
      <Text>
        Recibe tus pagos
        <br />
        <Text as="span" color="orange.500">
          puntualmente
        </Text>
        .
      </Text>
    ),
  },
  {
    icon: pagoMailBenefit3,
    text: (
      <Text>
        <Text as="span" color="orange.500">
          Haz una carga masiva de todos tus empleados y genera cuentas en línea.
        </Text>{" "}
        Te ayudará a facilitar el envío y a tener un mayor control de ellas.
      </Text>
    ),
  },
  {
    icon: pagoMailBenefit4,
    text: (
      <Text>
        <Text as="span" color="orange.500">
          Potencia tu negocio digital
        </Text>{" "}
        como PagoFlash Commerce (PSP).
      </Text>
    ),
  },
];

const bondadesPagoFlashBusiness: PagoMailBenefit[] = [
  {
    icon: masterTarjeta,
    text: (
      <Text>
        PagoFlash Maestro
        <br />
        <Text as="span" color="white" fontWeight={"normal"}>
          Puedes solicitar tu tarjeta de débito de una forma fácil y segura
          dentro de nuestra plataforma.
        </Text>
      </Text>
    ),
  },
  // {
  //   icon: qrPagoFlashAPP,
  //   text: (
  //     <Text>
  //       PagoFlash App
  //       <br />
  //       <Text as="span" color="white" fontWeight={"normal"}>
  //         A través de la app para móviles, podrás realizar gestionar su cuenta,
  //         realizar consultas, pagar servicios e iniciar transferencias. Además,
  //         puede administrar su propia información de seguridad y actualizar sus
  //         datos personales.
  //       </Text>
  //     </Text>
  //   ),
  // },
];

const recaudos: Recaudos[] = [
  {
    text: "Acta constitutiva y estatutos sociales debidamente inscritos en el Registro Mercantil competente.",
  },
  {
    text: "Documento inscrito en el Registro Mercantil, o copia de poder autenticado, donde se identifique el sujeto que puede representar a la empresa así como las facultades que detenta.",
  },
  {
    text: "Cédula de Identidad del Representante Legal.",
  },
  {
    text: "Registro Único de Información Fiscal (RIF) vigente.",
  },
  {
    text: "Estados Financieros de los tres (3) últimos ejercicios económicos.",
  },
];

const ComoFunctionaStep = ({ number }: { number: number }) => (
  <Box>
    <Center
      w="30px"
      h="30px"
      mr="4"
      bg="orange.500"
      color="white"
      borderRadius="50%"
      lineHeight="1"
    >
      {number}
    </Center>
  </Box>
);

const planilla = require("../assets/doc/planillaNomina.xlsx");

const PagoMailPage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Hero backgroundImage={bgHero}>
        <Box textAlign="center">
          <Heading size="3xl" color="white" textAlign="center" mb="8">
            PagoFlash Business - PSP
          </Heading>
          <Text
            color={"white"}
            fontWeight={600}
            lineHeight={1.3}
            fontSize={{ base: "3xl", md: "4xl" }}
            mb="8"
            opacity="0.85"
          >
            <Text as="span">
              Es un producto financiero, destinado a la recepción y movilización
              de fondos en moneda nacional
            </Text>
          </Text>
          <Text fontSize="2xl" maxW="xl" mx="auto" mb="8">
            Puedes descargar la planilla de contrato PagoFlash nómina (PSP), en
            el botón de abajo.
          </Text>
          <Stack
            direction={{ base: "column", lg: "row" }}
            justifyContent="center"
            spacing="8"
          >
            <a
              href={planilla}
              download="planilla contrato pago flash nomina.xlsx"
              target={"_blank"}
              rel="noreferrer noopener"
            >
              <Button
                rounded="sm"
                variant="outline"
                color="white"
                borderWidth="2px"
                size="lg"
                px="16"
                _hover={{ bg: "white", color: "black" }}
              >
                Descargar planilla
              </Button>
            </a>
            <Button
              bg="orange.500"
              rounded="sm"
              color="white"
              size="lg"
              _hover={{ bg: "orange.600" }}
              onClick={() => navigate(routes.asesoria)}
            >
              Agenda una asesoría
            </Button>
          </Stack>
        </Box>
      </Hero>

      <Section id="hero-bottom" position="relative" zIndex="1" bg="white">
        <Box maxW="5xl" mx="auto">
          <SectionHeader
            color="gray.700"
            textAlign={{ base: "center", md: "center" }}
            showDivider
          >
            <Text as="span" color="orange.500">
              ¿Cómo
            </Text>{" "}
            Registro mi empresa?
          </SectionHeader>

          <Stack
            direction={{ base: "column", lg: "row" }}
            justifyContent="center"
            spacing="32"
            alignItems="center"
          >
            {/* <Box textAlign="center">
              <DeviceFrame device="iPad Mini" color="black" size={2}>
                <Image src={pagoMail}></Image>
              </DeviceFrame>
            </Box> */}

            <VStack
              textAlign={{ base: "left", lg: "left" }}
              fontSize="xl"
              maxW="lg"
              mx="auto"
              spacing="16"
            >
              <HStack>
                <ComoFunctionaStep number={1} />

                <Box mb="12">
                  Descargue y realice el llenado del formulario de Registro
                  Integral de Persona Jurídica.
                </Box>
              </HStack>

              <HStack>
                <ComoFunctionaStep number={2} />

                <Box mb="12">
                  <VStack
                    direction={{ base: "column", lg: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    maxW="5xl"
                    mx="auto"
                  >
                    <Accordion allowMultiple>
                      <AccordionItem border="0">
                        {({ isExpanded }) => (
                          <>
                            <h2>
                              <AccordionButton pl="0">
                                <Box
                                  flex="1"
                                  textAlign="left"
                                  fontSize="xl"
                                  wordBreak={{
                                    base: "break-all",
                                    md: "normal",
                                  }}
                                >
                                  Envíe el formulario a nuestro correo
                                  atencionalcliente@pagoflash.com, junto a los
                                  siguientes recaudos:
                                </Box>
                                {isExpanded ? (
                                  <MinusIcon fontSize="12px" />
                                ) : (
                                  <AddIcon fontSize="12px" />
                                )}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {recaudos.map(({ text }, i) => (
                                <List mb={"4"}>
                                  <ListItem>
                                    <ListIcon
                                      as={CheckCircleIcon}
                                      color="orange.500"
                                    />
                                    {text}
                                  </ListItem>
                                </List>
                              ))}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  </VStack>
                </Box>
              </HStack>

              <HStack>
                <ComoFunctionaStep number={3} />

                <Box mb="12">
                  Recibirás un correo de confirmación y te estará contactando
                  nuestro departamento comercial para agendar una capacitación
                  del sistema.
                </Box>
              </HStack>

              <HStack>
                <ComoFunctionaStep number={4} />

                <Box>
                  Recibirás un correo de confirmación con el link de ingreso y
                  credenciales para hacer uso de la plataforma.
                </Box>
              </HStack>
            </VStack>
          </Stack>
        </Box>
      </Section>

      <Section spacing="sm" pb="0" px="0">
        <SectionHeader color="gray.700" textAlign="center" showDivider mb="16">
          Beneficios
        </SectionHeader>

        <SimpleGrid columns={{ base: 1, lg: 4 }} mx="auto">
          {pagoMailBenefit.map((benefit, i) => (
            <Box
              bgImage={`url(${bgParallaxTeal})`}
              py="16"
              px={{ base: "8", xl: "20" }}
              textAlign="center"
              key={i}
            >
              <Image src={benefit.icon} mx="auto" mb="8" />
              <Box fontSize="2xl" fontWeight="medium">
                {benefit.text}
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Section>

      {/* <Section spacing="sm" pb="0" px="0">
        <SectionHeader color="gray.700" textAlign="center" showDivider mb="16">
          Bondades que te ofrece PagoFlash Business
        </SectionHeader>

        <Box bgImage={`url(${bgParallaxTeal})`} py="8">
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            m="auto"
            gridGap="4"
            alignItems={"center"}
            p="auto"
          >
            <SimpleGrid
              templateRows={["1fr"]}
              textAlign="center"
              gridRowGap="4"
              alignItems={"center"}
              margin="auto"
            >
              {bondadesPagoFlashBusiness.map((benefit) => (
                <Flex alignItems={"center"}>
                  <Image
                    src={benefit.icon}
                    boxSize={{ base: "0px", md: "200px" }}
                    pl="8"
                  ></Image>
                  <Box key={benefit.icon} mx="14">
                    <Text color="orange.400" fontSize="2xl" fontWeight="medium">
                      {benefit.text}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </SimpleGrid>
            <Box m="auto">
              <Image src={tarjetaPagoflash} p={{ base: "4", sm: "" }}></Image>
            </Box>
          </SimpleGrid>
        </Box>
      </Section> */}

      <ConditionsSection2 />

      <Section spacing="sm" textAlign="center">
        <SectionHeader color="gray.700">
          <Text as="span" color="orange.500">
            Nuestras condiciones se adaptan a ti
          </Text>{" "}
          <br /> y aplican a todos nuestros productos
        </SectionHeader>
      </Section>

      <PreFooterSection />

      <Footer />
    </Box>
  );
};

export default PagoMailPage;
