import { Tooltip, TooltipProps, useBreakpointValue } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

interface Props extends TooltipProps {}

const PagoShopTooltip: FC<PropsWithChildren<Props>> = ({
  children,
  label,
  ...props
}) => {
  return (
    <Tooltip
      label={label}
      isOpen={useBreakpointValue({ base: false, lg: true })}
      hasArrow
      placement="top"
      gutter={16}
      background="#333"
      px="12"
      py="2"
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default PagoShopTooltip;
