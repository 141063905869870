import { Box, BoxProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

import bgParallax from "../../assets/images/parallax.png";

interface Props extends BoxProps {}

const CustomParallax: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return (
    <Box
      backgroundImage={`url(${bgParallax})`}
      backgroundSize="cover"
      backgroundAttachment="fixed"
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      {...props}
    >
      {children}
    </Box>
  );
};

export default CustomParallax;
