import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Stack, useDisclosure } from "@chakra-ui/react";
import NavLink from "./NavLink";
import { routes } from "../../routes/links";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logoPagoFLash.png";

interface INavLink {
  to?: string;
  href?: string;
  text: string;
  group?: string;
  children?: INavLink[];
}

const links: INavLink[] = [
  { to: routes.home, text: "Inicio" },
  {
    text: "Productos y Servicios",
    to: routes.products,
    children: [
      {
        to: routes.wallet,
        text: "PagoFlash Wallet Persona",
        group: "Productos",
      },
      { to: routes.business, text: "PagoFlash Business", group: "Productos" },
      { to: routes.commerce, text: "PagoFlash Commerce", group: "Servicio" },
      { to: routes.nomina, text: "PagoFlash Nomina", group: "Servicio" },
      // {
      //   href: "https://www.pagoflash.com/portal-remesas/",
      //   text: "Remesas",
      // },
    ],
  },
  // { to: routes.contacto, text: "Contacto" },
  // {
  //   text: "Servicios",
  //   children: [
  //     { to: routes.nomina, text: "PagoFlash Nomina" },
  //     // {
  //     //   href: "https://www.pagoflash.com/saldo/",
  //     //   text: "Consulta de Saldo",
  //     // },
  //   ],
  // },
  {
    href: "https://www.pagoflash.com/payment-commerce/login",
    text: "Iniciar Sesión",
  },
  { to: routes.soporte, text: "Soporte" },

  //{ to: routes.login, text: "Iniciar Sesión" },
];

export const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();

  const ref = useRef<HTMLDivElement>(null);

  const [bg, setBg] = useState("transparent");
  //const { user, signOut } = useAuth();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y >= window.screen.height - 150) {
        setBg("black");
      } else {
        setBg("transparent");
      }
    },
    undefined,
    { useWindow: true }
  );

  const _bg = useMemo(() => {
    const { pathname } = location;

    if (pathname.includes(routes.toc) || pathname.includes(routes.asesoria)) {
      return "black";
    }

    return bg;
  }, [location, bg]);

  return (
    <Box
      bg={{ base: isOpen ? "black" : "transparent", lg: _bg }}
      px={4}
      position="fixed"
      left="0"
      zIndex="2000"
      color="white"
      width="full"
      ref={ref}
      transition="background 1s"
    >
      <Flex
        alignItems={"center"}
        justifyContent={{ base: "flex-star", lg: "space-between" }}
        maxW="1200px"
        mx="auto"
        py="4"
      >
        <Stack w="300px">
          <img src={logo} alt="logo" />
        </Stack>

        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ lg: "none" }}
          onClick={isOpen ? onClose : onOpen}
          color="black"
        />
        {/* <HStack spacing={8} alignItems={"center"}>
          <Box>Logo</Box>
        </HStack> */}
        <Stack
          display={{ base: "none", lg: "flex" }}
          flex={{ base: 1, lg: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
          whiteSpace="nowrap"
          alignItems={"center"}
          fontWeight="medium"
          fontSize="lg"
        >
          <NavLink />
        </Stack>
      </Flex>
    </Box>
  );
};
