import {
  Box,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import CustomParallax from "../components/custom/CustomParallax";
import ProductsDescriptionSection from "../components/section/ProductsDescriptionSection";
import ProductSection from "../components/section/ProductSection";
import ProductsSection from "../components/section/ProductsSection";

import productsPagoClick1 from "../assets/images/products-pagoclick-1.png";
import productsPagoClick2 from "../assets/images/products-pagoclick-2.png";
import productsPagoClick3 from "../assets/images/products-pagoclick-3.png";

import productsPagoMail1 from "../assets/images/products-pagomail-1.png";
import productsPagoMail2 from "../assets/images/products-pagomail-2.png";
import productsPagoMail3 from "../assets/images/products-pagomail-3.png";

import productsPagoShop1 from "../assets/images/products-pagoshop-1.png";
import productsPagoShop2 from "../assets/images/products-pagoshop-2.png";
import productsPagoShop3 from "../assets/images/products-pagoshop-3.png";
import SectionHeader from "../components/section/SectionHeader";
import Section from "../components/section/Section";

import affiliate1 from "../assets/images/affiliate-1.png";
import affiliate2 from "../assets/images/affiliate-2.png";
import affiliate3 from "../assets/images/affiliate-3.png";
import affiliate4 from "../assets/images/affiliate-4.png";
import productsPago1 from "../assets/images/productsPage (1).png";
import productsPago2 from "../assets/images/productsPage (2).png";
import productsPago3 from "../assets/images/productsPage (3).png";

import { Link, useNavigate } from "react-router-dom";
import PreFooterSection from "../components/section/PreFooterSection";
import Footer from "../components/layout/Footer";
import { routes } from "../routes/links";

interface AffiliateInfo {
  icon: string;
  title: string;
  text: JSX.Element;
}

const affiliateInfo: AffiliateInfo[] = [
  {
    icon: affiliate1,
    title: "Crea tu cuenta en PagoFlash",
    text: (
      <Text>
        Regístrate haciendo clic <Link to="">Aquí</Link> y realiza una{" "}
        <Text as="span" fontWeight="medium">
          prueba gratis
        </Text>
        .
      </Text>
    ),
  },
  {
    icon: affiliate2,
    title: "Realiza el pago de afiliación",
    text: (
      <Text>
        Un monto único de afiliación bastará para que puedas disfrutar de
        nuestros productos.
      </Text>
    ),
  },
  {
    icon: affiliate3,
    title: "Envía los documentos requeridos",
    text: (
      <Text>
        Para{" "}
        <Text as="span" fontWeight="medium">
          tu seguridad
        </Text>{" "}
        necesitamos algunos requisitos que podrán variar según tu tipo de
        negocio.
      </Text>
    ),
  },
  {
    icon: affiliate4,
    title: "¡Úsalo!",
    text: (
      <Text>
        ¡Estás dentro! Sé parte de una comunidad de negocios que están en la era
        digital.
      </Text>
    ),
  },
];

const ProductsPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <CustomParallax
        backgroundBlendMode="overlay"
        backgroundColor={"blackAlpha.600"}
      >
        <Box
          paddingTop="180px"
          textAlign="center"
          mb={{ base: "20", sm: "6" }}
          paddingBottom={{ base: "24", lg: "0" }}
        >
          <Heading
            size="2xl"
            color="white"
            fontWeight="medium"
            transform={{ base: "", lg: "translateY(80px)" }}
          >
            <Text color="gray.800">
              NUESTROS PRODUCTOS ESTÁN HECHOS PARA TI
            </Text>
          </Heading>

          <Box
            my="32"
            transform="translateY(70px)"
            display={{ base: "none", lg: "block" }}
          >
            {/* <ProductsSection
              pulseColor="teal.300"
              showTooltips={useBreakpointValue({ base: false, lg: true })}
            /> */}
          </Box>
        </Box>
      </CustomParallax>

      <Box maxW="7xl" mx="auto">
        <ProductsDescriptionSection />
      </Box>

      <ProductSection
        image={productsPago3}
        title="PagoFlash Wallet (PSP)"
        description={
          <Box>
            <Text mb="8">
              <Text as="span" fontWeight="medium">
                Recibe pagos en redes sociales, chats como WhatsApp, ferias o
                bazares y más.{" "}
              </Text>
              Ideal para comercios que tienen estrategias de ventas en espacios
              físicos y online
            </Text>
            <Text fontWeight="medium">Lo puedes usar con:</Text>
          </Box>
        }
        items={[
          {
            icon: productsPagoClick1,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Monto fijo{" "}
                </Text>{" "}
                para <br /> tus productos o servicios
              </Text>
            ),
          },
          {
            icon: productsPagoClick2,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Sin monto fijo{" "}
                </Text>{" "}
                para <br />
                donaciones y bazares
              </Text>
            ),
          },
          {
            icon: productsPagoClick3,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Catálogo{" "}
                </Text>{" "}
                para <br />
                agrupar tus enlaces
              </Text>
            ),
          },
        ]}
        variant="color"
        onKnowMoreClick={() => navigate(routes.wallet)}
        onWatchTutorialClick={() => null}
      />

      <ProductSection
        image={productsPago2}
        title="PagoFlash Business (PSP)"
        description={
          <Box>
            <Text mb="8">
              <Text as="span" fontWeight="medium">
                Envía solicitudes de cobro.{" "}
              </Text>
              al correo electrónico de tus clientes.
            </Text>
            <Text fontWeight="medium">Lo puedes usar con:</Text>
          </Box>
        }
        items={[
          {
            icon: productsPagoMail1,
            text: (
              <Text>
                Pago de <br />
                <Text as="span" fontWeight="medium">
                  mensualidades
                </Text>
              </Text>
            ),
          },
          {
            icon: productsPagoMail2,
            text: (
              <Text>
                Pagos <br />
                <Text as="span" fontWeight="medium">
                  masivos
                </Text>
              </Text>
            ),
          },
          {
            icon: productsPagoMail3,
            text: (
              <Text>
                Pagos <br />
                <Text as="span" fontWeight="medium">
                  personalizados
                </Text>
              </Text>
            ),
          },
        ]}
        variant="white"
        onKnowMoreClick={() => navigate(routes.business)}
        onWatchTutorialClick={() => null}
      />

      {/* <ProductSection
        image={productsPago1}
        title="PagoFlash Commerce"
        description={
          <Box>
            <Text mb="8">
              <Text as="span" fontWeight="medium">
                Botón de pago para tu ecommerce. Nunca había sido tan sencillo
                pagar en una página web.
              </Text>
            </Text>
            <Text fontWeight="medium">
              Con{" "}
              <Text as="span" color="orange.500">
                PagoFlash Commerce
              </Text>{" "}
              podrás lograr:
            </Text>
          </Box>
        }
        items={[
          {
            icon: productsPagoShop1,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Integración sencilla
                </Text>{" "}
              </Text>
            ),
          },
          {
            icon: productsPagoShop2,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Formulario amigable
                </Text>{" "}
              </Text>
            ),
          },
          {
            icon: productsPagoShop3,
            text: (
              <Text>
                <Text as="span" fontWeight="medium">
                  Vender 24/7
                </Text>{" "}
              </Text>
            ),
          },
        ]}
        variant="color"
        onKnowMoreClick={() => navigate(routes.commerce)}
        onWatchTutorialClick={() => null}
      /> */}

      <Section>
        <SectionHeader color={"gray.700"} showDivider textAlign="center">
          Ser parte de PagoFlash (PSP) es{" "}
          <Text as="span" color="orange.500">
            absurdamente sencillo
          </Text>
        </SectionHeader>

        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          maxW="6xl"
          mx="auto"
          px={{ base: 8, lg: 0 }}
          gridRowGap="16"
          gridColumnGap="32"
        >
          {affiliateInfo.map((info) => (
            <HStack spacing="8" key={info.icon}>
              <Box>
                <Image src={info.icon} boxSize="80px" />
              </Box>
              <Box flex="1">
                <Text fontWeight="medium" fontSize={{ base: "2xl", sm: "3xl" }}>
                  {info.title}
                </Text>
                <Box fontSize={{ base: "xl", sm: "xl" }}>{info.text}</Box>
              </Box>
            </HStack>
          ))}
        </SimpleGrid>
      </Section>

      <PreFooterSection />

      <Footer />
    </Box>
  );
};

export default ProductsPage;
