import { Box, Heading, ListItem, UnorderedList, Text } from "@chakra-ui/react";
import Footer from "../components/layout/Footer";

const Privacy = () => {
  return (
    <Box>
      <Box py="32">
        <Box maxW="4xl" mx="auto" boxShadow="base" py="8" px="16">
          <Heading fontWeight="thin" mb="8">
            Políticas de Privacidad{" "}
          </Heading>
          <Text fontWeight="thin" fontSize="lg">
            PAGOFLASH INTERNATIONAL C.A. (en lo sucesivo los “PagoFlash”),
            sociedad mercantil inscrita en el Registro Mercantil Segundo de la
            Circunscripción Judicial del Distrito Capital y Estado Miranda, en
            fecha diez (10) de junio del año dos mil trece (2013), bajo el N° 93
            Tomo 61-A-SDO, refundidos sus estatutos por ante el mismo Registro
            en fecha 20 de marzo de 2018, bajo el Nro. 6 Tomo 63-A Sdo, cuya
            última Acta de Asamblea General Extraordinaria de Accionistas fue
            debidamente registrada en fecha 31 de enero de 2020, bajo el Nro.
            39, Tomo 19-A sdo identificada con el Registro Único de Información
            Fiscal (RIF) J-40256916-5, en adelante PAGOFLASH, publica las
            siguientes Políticas de Privacidad, las cuales serán aceptadas por
            los Clientes a través del suministro de forma voluntaria de la
            información requerida por el uso de la APLICACIÓN MÓVIL “APP
            PAGOFLASH” publicada para ser descargado en la tienda Play Store.
            Los Clientes entienden y aceptan estas políticas de privacidad, así
            como las condiciones de uso de la aplicación, que regula la
            recopilación de la información, su almacenamiento y procesamiento de
            la información personal y transaccional.
            <br />
            <br />
            <UnorderedList spacing={3}>
              <ListItem>
                PAGOFLASH podrá recopilar información de los clientes a través
                de la APP PagoFlash, la información que se obtiene permitirá a
                PAGOFLASH analizar el tráfico y adaptar las funcionalidades de
                la APP PagoFlash a las necesidades de los clientes.
              </ListItem>
              <ListItem>
                Los clientes a su voluntad proporcionaran información personal
                referida a su identificación y datos de contacto, tales como
                nombres, apellidos, nacionalidad, fecha de nacimiento, actividad
                económica, dirección de habitación, correo y número de línea
                móvil celular; los cuales son datos necesarios para utilizar
                APP, además de mostrar información de cuenta transaccional,
                recibir actualizaciones, notificaciones y servicios de atención
                al cliente, ofertas de servicios relacionados y otros
                intercambios de información desde la App PagoFlash.
              </ListItem>
              <ListItem>
                PAGOFLASH no revelará ni compartirá la información con ninguna
                empresa u organización sin el consentimiento previo de los
                clientes, excepto cuando así lo establezca la Ley por
                requerimiento de algún Organismo Administrativo o Judicial
                competente para ello.
              </ListItem>
              <ListItem>
                PAGOFLASH utilizará la información personal e identificable de
                los clientes para permitirles utilizar los servicios de la App
                PagoFlash, en este sentido PAGOFLASH utilizará la información
                para: 1) Permitirles utilizar los servicios y funciones del
                servicio App PagoFlash. 2) Responderles a sus consultas,
                solicitudes de asistencia o preguntas. 3) Enviarles
                actualizaciones, noticias, ofertas especiales o información
                sobre productos y servicios que puedan ser de su interés. 4)
                Procesar transacciones. 5). Mejorar y optimizar el servicio App
                PagoFlash.
              </ListItem>
              <ListItem>
                Si PAGOFLASH decidiera recopilar y utilizar la información
                personal de los clientes, para cualquier fin que no se haya
                enumerado anteriormente, PAGOFLASH informará previamente a los
                clientes sobre el uso de dicha información, a través de los
                medios correspondientes.
              </ListItem>
              <ListItem>
                PAGOFLASH ofrece a los clientes la posibilidad de corregir o
                cambiar la información suministrada, en cualquier momento que lo
                requieran a través de los procesos establecidos para tal fin.
              </ListItem>
              <ListItem>
                La App PagoFlash emplea conexiones seguras y otras medidas de
                seguridad destinadas a salvaguardar la confidencialidad de la
                información de los clientes, siempre y cuando los clientes
                cumplan con todas las recomendaciones de seguridad.
              </ListItem>
              <ListItem>
                La transmisión de datos por medio del servicio App PagoFlash
                estará debidamente codificada mediante la tecnología de
                encriptación, para suministrar al servicio la mayor seguridad
                posible, así como a las consultas, operaciones y transacciones
                que por su intermedio se realizaren y a las solicitudes e
                instrucciones que se enviaren.
              </ListItem>
              <ListItem>
                PAGOFLASH, garantiza la privacidad y la confiabilidad de los
                datos e información implicada en las transacciones realizadas a
                través de la App PagoFlash, de forma tal que la información
                intercambiada no sea accesible a terceros no autorizados, de
                acuerdo a lo establecido en las leyes que rigen la materia.
                PAGOFLASH, no será responsable por los daños ocasionados a los
                equipos o programas por la posible contaminación de virus
                informáticos, a los cuales PAGOFLASH no tenga acceso.
              </ListItem>
              <ListItem>
                Las modificaciones a las políticas de privacidad aquí
                enumeradas, serán efectuadas por PAGOFLASH y serán publicadas,
                entrando en vigencia en la oportunidad que se indica en las
                referidas condiciones generales. PAGOFLASH se reserva el derecho
                de actualizarlas, notificando a los clientes sobre cambios
                significativos.
              </ListItem>
              <ListItem>
                Queda entendido que todo aquello que no esté previsto en estas
                políticas, se aplicará lo establecido en los TÉRMINOS GENERALES
                DE USO DE LA APLICACIÓN MÓVIL “APP PAGOFLASH”
              </ListItem>
            </UnorderedList>
          </Text>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default Privacy;
