import {
  Box,
  Center,
  Heading,
  Stack,
  Text,
  Image,
  Button,
  Flex,
  chakra,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import DeviceFrame from "../components/devices/DeviceFrame";
import { Slide } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { doYouKnow, slides } from "../data";

import Hero from "../components/layout/Hero";

import bgHero from "../assets/images/hero-home.jpg";
import tabletBG from "../assets/images/Tablet.jpg";
import CustomParallax from "../components/custom/CustomParallax";
import { homeSteps } from "../data";

import ProductStepsSection from "../components/section/ProductStepsSection";
import SectionHeader from "../components/section/SectionHeader";
import Footer from "../components/layout/Footer";
import Section from "../components/section/Section";
import ProductsSection from "../components/section/ProductsSection";
import ProductsDescriptionSection from "../components/section/ProductsDescriptionSection";
import { routes } from "../routes/links";
import { useNavigate } from "react-router-dom";

const bgsabesporque = require("../assets/images/gb-sabesporquenoseligen.jpg");
const bgPhone = require("../assets/images/img-phone.jpg");

const ChakraSwiper = chakra(Swiper);

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Hero backgroundImage={bgHero}>
        <Text
          color={"white"}
          fontWeight={700}
          lineHeight={1.2}
          fontSize={useBreakpointValue({ base: "3xl", md: "4xl", xl: "5xl" })}
        >
          ¡Creamos soluciones absurdamente sencillas! Junto a nosotros, las
          empresas y personas naturales pueden realizar y recibir pagos a toda
          hora y en cualquier lugar.
        </Text>
        <Stack direction={"row"}>
          <Button
            bg="white"
            rounded="sm"
            color="black"
            size="lg"
            _hover={{ bg: "orange.500" }}
            onClick={() => navigate(routes.asesoria)}
          >
            Agenda una asesoría
          </Button>
        </Stack>
      </Hero>

      <Box id="hero-bottom" position="relative" zIndex="1">
        {/* <Parallax bgImage={bgBottomHero} bgImageAlt="the cat" strength={600}> */}
        <CustomParallax>
          <Section>
            <Text
              maxW="1050px"
              mx="auto"
              fontSize={{ base: "3xl", sm: "4xl" }}
              fontWeight="medium"
              color="white"
              textAlign="center"
              textShadow=""
            >
              PagoFlash, actuando como PSP autorizado por el Banco Central de
              Venezuela, provee servicios de pago basados en tecnología móvil y
              web con la finalidad de facilitar la interacción entre
              consumidores y comercios en Internet.
            </Text>
            <Text
              mt="8"
              maxW="1050px"
              mx="auto"
              fontSize="xl"
              color="white"
              textAlign="center"
              textShadow=""
            >
              Proveedor no Bancario de Servicios de Pago, autorizado por el
              Banco Central de Venezuela en fecha 08 de noviembre de 2021, bajo
              el Nro. 010.
            </Text>
          </Section>
        </CustomParallax>
        {/* </Parallax> */}
      </Box>
      <Box height="500px" py="16" mb="13rem">
        <Box maxW="1200px" mx="auto">
          <Heading
            size="3xl"
            fontWeight="regular"
            paddingLeft={{ base: "8", xl: "0" }}
          >
            Nuestros{" "}
            <Text as="span" color="orange.500">
              productos
            </Text>
          </Heading>

          {/* <Center mt="16" display={{ base: "none", sm: "flex" }}>
            <ProductsSection />
          </Center> */}

          <ProductsDescriptionSection />
        </Box>

        {/* <ProductStepsSection
          heading="Es tan fácil como..."
          steps={homeSteps}
          footer="Empieza a recibir pagos de forma segura y absurdamente sencilla…"
        /> */}

        {/* <Box maxW="1200px" mx="auto" marginTop={"10"}>
          <SectionHeader color="gray.700">
            Sabes{" "}
            <Text color="orange.500" as="span">
              por qué{" "}
            </Text>
            nos eligen?{" "}
          </SectionHeader>

          <Box textAlign="center" py="16" bg="brand.500" color="black.500">
            <Slide duration={1000} direction="up" triggerOnce>
              <Stack
                direction={{ base: "column", lg: "row" }}
                justifyContent="center"
                spacing={{ base: "12", lg: "48" }}
                alignItems={{ base: "center", lg: "center" }}
              >
                <VStack
                  alignItems={"start"}
                  direction="column"
                  bgImage={{ base: "", md: `url(${bgsabesporque})` }}
                  bgRepeat="no-repeat"
                  bgSize="contain"
                  maxW={"600px"}
                  justifyContent="center"
                  paddingRight={{ base: 4, md: 0 }}
                  paddingLeft={{ base: 4, md: "12rem" }}
                  spacing="5rem"
                >
                  {doYouKnow.map((description, i) => (
                    <Box key={i} textAlign="left">
                      {description.map(({ text, highlight }, i) => (
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          as="span"
                          key={i}
                          color={highlight ? "orange.500" : "gray.700"}
                          alignContent="start"
                        >
                          {text}{" "}
                        </Text>
                      ))}
                    </Box>
                  ))}
                </VStack>

                <DeviceFrame device="iPhone X" color="black" size={1.8}>
                  <Box
                    bgImage={bgPhone}
                    height="100%"
                    bgSize={"contain"}
                    bgRepeat="no-repeat"
                    bgPosition={"center"}
                    bgColor="orange.500"
                  ></Box>
                </DeviceFrame>
              </Stack>
            </Slide>
          </Box>
        </Box> */}

        {/* <Box py="12">
          <Flex justifyContent="center" px="8">
            <Text
              color="gray.700"
              fontSize={{ base: "5xl", sm: "6xl" }}
              fontWeight="medium"
              textAlign={"center"}
            >
              Testimonios{" "}
              <Text color="orange.500" as="span">
                PagoLovers
              </Text>
            </Text>
          </Flex>

          <ChakraSwiper
            navigation={true}
            modules={[Navigation, Autoplay]}
            display="flex"
            alignItems="stretch"
            justifyContent="center"
            maxWidth="1200px"
            mx="auto"
            autoplay={{
              delay: 3000,
            }}
            loop={true}
          >
            {slides.map((slide, i) => (
              <SwiperSlide style={{ height: "unset" }} key={i}>
                <Box
                  px={{ base: "50px", lg: "200px" }}
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {slide.image && <Image src={slide.image} mb="12" />}
                  <Text
                    textAlign="center"
                    fontSize="lg"
                    color="blackAlpha.800"
                    mb="4"
                  >
                    {slide.text}
                  </Text>
                  <Text fontSize="xl" fontWeight="medium">
                    {slide.name}
                  </Text>
                  <Text color="blackAlpha.700">{slide.position}</Text>
                </Box>
              </SwiperSlide>
            ))}
          </ChakraSwiper>
        </Box> */}

        <Center
          height="961px"
          backgroundImage={`url(${tabletBG})`}
          // backgroundSize={{ base: "300%", md: "150%", lg: "cover" }}
          backgroundSize="cover"
          backgroundPosition="center center"
        >
          <Box textAlign="center">
            <Text mb="8" fontSize="3xl" fontWeight="medium">
              ¿Listo para empezar y no sabes cómo?
            </Text>
            <Button
              colorScheme="orange"
              size="lg"
              borderRadius="2px"
              onClick={() => navigate(routes.asesoria)}
            >
              Agenda una asesoría
            </Button>
          </Box>
        </Center>

        <Footer />
      </Box>
    </Box>
  );
};

export default HomePage;
