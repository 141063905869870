import { Box, BoxProps } from "@chakra-ui/react";
import React, { FC, PropsWithChildren, useMemo } from "react";

interface Props extends BoxProps {
  spacing?: "sm" | "md" | "lg";
}

const Section: FC<PropsWithChildren<Props>> = ({
  children,
  spacing = "md",
  ...props
}) => {
  const padding = useMemo(() => {
    switch (spacing) {
      case "sm":
        return "16";
      case "md":
        return "24";
      case "lg":
        return "32";
      default:
        return "24";
    }
  }, [spacing]);

  return (
    <Box py={padding} px="4" {...props}>
      {children}
    </Box>
  );
};

export default Section;
