import { Box, Flex, Stack, HStack, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { socialRed } from "../../data";
import { routes } from "../../routes/links";

const Footer = () => {
  const footerLinks = [
    // {
    //   to: routes.nosotros,
    //   text: "Acerca de PagoFlash",
    // },
    {
      to: routes.toc,
      text: "Términos y condiciones.",
    },
    {
      to: routes.privacy,
      text: "Privacidad",
    },
    {
      to: routes.soporte,
      text: "Soporte",
    },
    // {
    //   to: routes.contacto,
    //   text: "Contáctanos",
    // },
  ];

  return (
    <Box bg="#1a1a1a" color="white" py="4" px="4">
      <Flex
        flexDirection={{ base: "column", xl: "row" }}
        maxW="1200px"
        mx="auto"
        fontSize="sm"
        justifyContent="space-between"
        alignItems={"flex-start"}
      >
        <Box>
          <Text mt={{ base: 0, lg: 4 }}>
            PagoFlash International C.A. RIF J - 40256916 - 5
          </Text>
          <Text mb="8">
            {/* Este sitio está autorizado por Visa para realizar transacciones
            electrónicas. */}
          </Text>

          <Text mt={{ base: 0, lg: 2 }} mb="4">
            Dirección: Av. Francisco de Miranda con calle los cortijos, Edif.
            Torre Europa piso 8 entre oficina 8b1-8b2, Urb. el Rosal.
            Caracas-Venezuela
          </Text>

          <Text maxW="900px" opacity={0.9} textAlign="justify">
            La administración de los fondos de los usuarios de los servicios es
            responsabilidad exclusiva de PagoFlash International C.A. – PSP, los
            usuarios pueden solicitar en cualquier momento el retiro o
            transferencia parcial o total de los fondos disponibles en sus
            cuentas, así mismo el Banco Central de Venezuela no es responsable
            ni garantiza los fondos entregados a PagoFlash International C.A. –
            PSP para su administración.
          </Text>
        </Box>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "flex-start", lg: "center" }}
          justifyContent={{ base: "flex-end" }}
          ml="auto"
          w="100%"
          my={{ base: "4", xl: "0" }}
        >
          <Stack direction={{ base: "column", md: "row" }} spacing="4">
            {footerLinks.map(({ to, text }) => (
              <Text _hover={{ color: "orange.500" }} key={to}>
                <RouterLink to={to}>{text}</RouterLink>
              </Text>
            ))}
          </Stack>

          <HStack
            spacing={{ base: "4", lg: "4" }}
            justifyContent="center"
            ml={{ base: 0, lg: "8" }}
            my={{ base: "4", lg: "0" }}
          >
            {socialRed.map(({ icon, link, hoverBg: hoverColor }, i) => (
              <Box
                color="#999"
                padding="8px"
                fontSize="1.5rem"
                transitionProperty="background, color"
                transitionDuration="1s"
                borderRadius="50%"
                _hover={{
                  background: hoverColor,
                  color: "white",
                }}
                key={i}
              >
                <Link href={link}>{icon}</Link>
              </Box>
            ))}
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
