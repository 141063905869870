import { VStack, Flex, Text } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";

interface DatosContacto {
  title: string;
  icon: JSX.Element;
}

interface Props {
  email: string;
}

//soporte@pagoflash.com

const ContactDataSection: FC<Props> = ({ email }) => {
  const datosContacto: DatosContacto[] = useMemo(
    () => [
      {
        title: "A nuestra oficina +58 42422265478 ",
        icon: <BsFillTelephoneFill />,
      },
      {
        title: `Al correo electrónico ${email}`,
        icon: <IoMdMail />,
      },
    ],
    [email]
  );

  return (
    <VStack
      //direction={{ base: "column", lg: "row" }}
      justifyContent="center"
      maxW="6xl"
      spacing="12"
      mx="auto"
      mb="24"
      alignItems="center"
    >
      {datosContacto.map((option) => (
        <Flex
          textAlign="center"
          alignItems="center"
          marginTop="8"
          px="8"
          key={option.title}
        >
          <Text fontSize="4xl" fontWeight="medium" mr="4" textAlign="center">
            {option.icon}
          </Text>
          <Text
            fontSize={{ base: "xl", lg: "4xl" }}
            fontWeight="medium"
            textAlign="center"
            color="white"
          >
            {option.title}
          </Text>
        </Flex>
      ))}
    </VStack>
  );
};

export default ContactDataSection;
