import { Box, Flex } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";

interface Props {
  children: JSX.Element[];
  itemWidth: number;
}

const CustomAccordion: FC<Props> = ({ children, itemWidth }) => {
  const defaultTranslateX = [...Array(children.length)].map(() => 0);
  const [translateX, setTranslateX] = useState(defaultTranslateX);

  const { width: screenWidth } = useWindowSize();

  const ratio = 1 / children.length;
  const ratioPercent = ratio * 100;

  const itemScreenRatio = itemWidth / screenWidth;
  const hiddenRatio = itemScreenRatio - ratio;
  const fullRatio = hiddenRatio / ratio;
  const fullRatioPercent = fullRatio * 100;
  const eachFullRatioPercent = fullRatioPercent / (children.length - 1);

  const onItemMouseEnter = (nth: number) => {
    setTranslateX((prev) => {
      const arr = [...prev];

      const change = eachFullRatioPercent * nth;

      arr[nth] = change * -1;

      for (let i = 1; i < nth; i++) {
        arr[i] = eachFullRatioPercent * i * -1;
      }

      for (let i = nth + 1; i < prev.length; i++) {
        arr[i] = eachFullRatioPercent * (prev.length - i);
      }

      return arr;
    });
  };

  const onItemMouseLeave = (nth: number) => {
    setTranslateX(defaultTranslateX);
  };

  return (
    <Flex
      overflow="hidden"
      position="relative"
      flexDirection={{ base: "column", lg: "row" }}
    >
      {children.map((element, i) => (
        <Box
          w={{ base: "100%", lg: `${ratioPercent}%` }}
          onMouseOver={onItemMouseEnter.bind(null, i)}
          onMouseOut={onItemMouseLeave.bind(null, i)}
          transform={`translateX(${translateX[i]}%)`}
          transition="transform 0.5s"
          boxShadow="-1px 0px 20px #0000008c"
          key={i}
        >
          {element}
        </Box>
      ))}
    </Flex>
  );
};

export default CustomAccordion;
