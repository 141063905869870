import { ChevronDownIcon } from "@chakra-ui/icons";
import { Stack, Flex, VStack, Box, useBreakpointValue } from "@chakra-ui/react";
import { FC, PropsWithChildren, useRef, useState } from "react";

import { useScrollPosition } from "../../hooks/useScrollPosition";
import useWindowSize from "../../hooks/useWindowSize";

interface Props {
  backgroundImage: string;
}

const Hero: FC<PropsWithChildren<Props>> = ({ backgroundImage, children }) => {
  // const backgroundImage = `url(${background})`;

  const ref = useRef<HTMLElement>();

  const { width: screenWidth } = useWindowSize();

  const [translateY, setTranslateY] = useState(0);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (screenWidth < 16 * 48) {
        return;
      }

      if (ref.current && ref.current.offsetHeight >= Math.abs(currPos.y)) {
        setTranslateY(Math.abs(currPos.y) * 0.75);
      } else {
        setTranslateY(0);
      }
    },
    undefined,
    { element: ref as any }
  );

  const onArrowClick = () => {
    const element = document.querySelector("#hero-bottom");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <Flex
      w="full"
      minH="100vh"
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      backgroundPosition="center center"
      position="relative"
      ref={ref as any}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.900, transparent)"}
      >
        <Stack
          maxW={"5xl"}
          align={"flex-start"}
          spacing={6}
          transform={`translateY(${translateY}px)`}
          color="white"
          py="16"
        >
          {children}
        </Stack>
      </VStack>

      <Box cursor="pointer" onClick={onArrowClick}>
        <ChevronDownIcon
          position="absolute"
          bottom="8"
          mx="auto"
          left="0"
          right="0"
          color="white"
          fontSize="6xl"
          animation="fade-move-down 2s linear infinite"
        />
      </Box>
    </Flex>
  );
};

export default Hero;
